@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap);
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  background: #030303;
  color: #FFFFFF;
}

.styles_headerRoot__1NnCM {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 40px 0 32px;
}
.styles_logoText__2dj0S {
  margin-bottom: 16px;
}
.styles_logo__3zu4Y {
  height: 70px;
  width: 70px;
  background: #FFFFFF;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

h1 {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  margin: 0;
}
.styles_dot__1PX-Z {
  color: #10D078;
}
p {
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  margin: 16px 0 0;
}
.styles_bgPic1__2Z2J3 {
  background-image: url(/static/media/bg1.7ab0a494.png);
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 522px;
  text-align: center;
}
.styles_btn__1sIYu {
  font-family: Montserrat;
  width: 186px;
  height: 40px;
  border-radius: 4px;
  border: none;
  outline:none;
  padding: 0;
}
.styles_getBtn__35wRs {
  background: #FFFFFF;
  margin: 273px auto 105px;
}
.styles_getBtnLogo__3bD3e{
  margin-top: 6px;
}
.styles_installBtn__2Chvv {
  background: #10D078;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #FFFFFF;
  display: block;
  margin: 25px auto 57px;
}
.styles_bgPic2__1VhbK {
  background-image: url(/static/media/bg2.1e5fcb43.png);
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 374px;
  text-align: center;
  background-size: cover;
}
.styles_wrapper__1vbEw {
  padding-top: 261px;
}
.styles_getBtn2__hejmj {
  background: #FFFFFF;
  display: block;
  margin: 20px auto 55px;
}

.styles_ratingRoot__3kAVZ {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 12px;
}
.styles_text__303IO {
  font-weight: normal;
  font-size: 10px;
  line-height: 24px;
  margin-left: 16px;
  margin-right: 4px;
}

.styles_cardRoot__1gquH {
  width: 246px;
  height: 233px;
  background: #181C1F;
  border-radius: 4px;
  padding: 32px 24px;
  margin-right: 8px;
}
.styles_androidName__1qXfd {
  font-weight: bold;
  font-size: 14px;
  line-height: 32px;
  padding-right: 8px;
}
.styles_androidDate__1VxaF {
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  color: #9B9B9B;
}
.styles_androidWrapper__2yVy3 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 19px;
}
.styles_androidStars__mtDKm {
  margin: 8px 0;
}
.styles_comment__7Fqga {
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
}
.styles_iosStars__2ja7B {
  padding: 14px 0 8px;
}
.styles_iosNameDate__27Msd {
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #9B9B9B;
}
.styles_title__1ymXo {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  padding: 14px 0 2px;
}

.styles_footerRoot__1AdXE {
  height: 62px;
  background: #181C1F;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.footerRoot {
  height: 62px;
  background: #181C1F;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
}
